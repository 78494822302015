<template>
  <div>
    <header-slot>
        <template #actions>
            <b-button variant="success" @click="openModalNewLetter">
                <feather-icon icon="PlusIcon" /> Create
            </b-button>
        </template>
    </header-slot>
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'pending-letters-specialistdigital' }"
        exact
        :active="$route.meta.typeTab === 'pending'"
        :link-classes="['px-3', bgTabsNavs]"
      >PENDING
    </b-nav-item>
    <b-nav-item
        :to="{ name: 'completed-letters-specialistdigital' }"
        exact
        :active="$route.meta.typeTab === 'completed'"
        :link-classes="['px-3', bgTabsNavs]"
      >COMPLETED
    </b-nav-item>
    <b-nav-item
        :to="{ name: 'returned-letters-specialistdigital' }"
        exact
        :active="$route.meta.typeTab === 'returned'"
        :link-classes="['px-3', bgTabsNavs]"

      >RETURNED
        <span class="ml-2" v-if="returnedLetters > 0">
          <span>
            <feather-icon
              icon
              :badge="returnedLetters"
              badge-classes="badge-danger badge-glow"
            />
          </span>
        </span>
    </b-nav-item>  
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <modal-create-round-letter 
      v-if="modalNewLetters"
      @updateTable="closeModalLetter"
      @hideModal="modalNewLetters = false;"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalCreateRoundLetter from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalCreateRoundLetter.vue";
export default {
  components: {
      ModalCreateRoundLetter,
  },
  data() {
      return {
          modalNewLetters: false,
          keyTable: 0
      }
  },
  computed: {
    ...mapGetters({
      returnedLetters: 'SpecialistsDigitalRoundLettersStore/G_COUNTER_RETURNED_ROUND_LETTERS',
    }),
  },
  methods: {
      openModalNewLetter() {
          this.modalNewLetters = true
      },
      closeModalLetter() {
          this.A_UPDATE_TABLE(true)
          this.modalNewLetters = false
          this.keyTable++;
      },
      ...mapActions({
        A_UPDATE_TABLE: 'SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE'
      })
  },
}
</script>
<style scoped>
</style>
