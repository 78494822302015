<template>
  <div>
    <b-modal
      v-model="modalOpened"
      size="lg"
      title-class="h3 text-white font-weight-bolder"
      :title="'CREATE ' + text_name"
      header-class="b-vue-modal-header"
      modal-class="top-modal"
      hide-footer
      @hidden="closeModal"
    >
      <div>
        <ValidationObserver ref="form">
          <b-row>
            <b-col md="6" xs="12">
              <b-row>
                <b-col md="12">
                  <ValidationProvider
                    name="applicant"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group class="mt-2">
                      <b-input-group>
                        <b-input-group-prepend class="w-35">
                          <b-input-group-text class="w-100">
                            <span>ACCOUNT</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="account"
                          type="text"
                          :style="{
                            border: errors[0]
                              ? '1px solid #fc424a !important'
                              : '',
                          }"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button variant="info" @click="searchUser">
                            <template v-if="isLoading">
                              <b-spinner small />
                              <span>Loading...</span>
                            </template>
                            <span v-else
                              ><feather-icon icon="SearchIcon" /> Search</span
                            >
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" xs="12">
              <b-row>
                <b-col md="12">
                  <b-form-group class="mt-2">
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>CLIENT</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="nameClient"
                        type="text"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>TYPE</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="type_send"
                      :style="{
                        border: errors[0] ? '1px solid #fc424a !important' : '',
                      }"
                      style="appearance: none !important"
                    >
                      <b-form-select-option value="4" v-if="direct"
                        >PHONE</b-form-select-option
                      >
                      <b-form-select-option value="5" v-if="direct"
                        >ONLINE</b-form-select-option
                      >
                      <b-form-select-option value="1" v-if="!direct"
                        >REGULAR</b-form-select-option
                      >
                      <b-form-select-option value="2" v-if="!direct"
                        >CERTIFIED</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" v-if="type_send == 2">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>BUREAU</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="bureau_id"
                      :style="{
                        border: errors[0] ? '1px solid #fc424a !important' : '',
                      }"
                      required
                      style="appearance: none !important"
                    >
                      <b-form-select-option
                        v-for="item in bureaus"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.name }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" v-if="!direct">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>TITLE</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="title"
                      :style="{
                        border: errors[0] ? '1px solid #fc424a !important' : '',
                      }"
                      required
                      style="appearance: none !important"
                      :disabled="direct"
                    >
                      <b-form-select-option
                        v-for="item in titles"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.value }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" v-if="!direct">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>FORMAT</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="format"
                      :style="{
                        border: errors[0] ? '1px solid #fc424a !important' : '',
                      }"
                      required
                      style="appearance: none !important"
                    >
                      <b-form-select-option
                        v-for="item in formats"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.value }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" xs="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group class="mt-2">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>SECURE NUMBER</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-radio-group
                      v-model="social"
                      class="form-control text-center bg-transparent"
                      :style="{
                        border: errors[0] ? '1px solid #fc424a !important' : '',
                      }"
                    >
                      <b-form-radio
                        :value="ss.ssn"
                        v-if="ss.ssn"
                        @change="changeSocialType(1)"
                        >{{ "SSN: " + ss.ssn }}</b-form-radio
                      >
                      <b-form-radio
                        :value="ss.itin"
                        v-if="ss.itin"
                        @change="changeSocialType(2)"
                        >{{ "ITIN: " + ss.itin }}</b-form-radio
                      >
                      <b-form-radio
                        :value="ss.other"
                        v-if="ss.other"
                        @change="changeSocialType(3)"
                        >{{ "CPN: " + ss.other }}</b-form-radio
                      >
                    </b-radio-group>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-row class="class-inline">
                <b-col
                  md="5"
                  class="class-campo-icon add-class-campo-icon"
                  style="border-radius: 10px 10px 0px 0px"
                >
                  <span>OBSERVATION</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <div class="form-group mt-0">
                <b-form-textarea
                  v-model="obs"
                  class="b-form-style wysiwyg-notes"
                  style="border-radius: 0px 10px 10px 10px"
                ></b-form-textarea>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <div class="offset-4 col-4 text-center">
              <b-button
                variant="success"
                class="rounded mt-2 mb-1"
                @click="saveRoundLetter()"
                :disabled="!firstsave"
              >
                <i class="fas fa-save"></i>
                SAVE
              </b-button>
            </div>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import LettersService from "@/views/specialist-digital/views/letters-round/services/letters-round.service.js";
import ClientesOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import specialistsCounter from "@/views/specialists/specialists-counter";
import analystDepartmentCounter from "@/views/specialists/sub-modules/analyst-department/analyst-department-counter";
export default {
  props: {
    direct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titles: [],
      formats: [],
      bureaus: [],
      text_name: this.direct ? "DIRECT DISPUTE" : "DISPUTE LETTER",
      title: "",
      format: "1",
      modalOpened: true,
      isLoading: false,
      obs: "",
      account: "",
      idaccount: "",
      nameClient: "",
      type_send: null,
      bureau_id: null,
      firstsave: false,
      ss: {
        ssn: null,
        itin: null,
        other: null,
      },
      social: null,
      type_social: "",
    };
  },
  async created() {
    this.addPreloader();
    await Promise.all([
      this.getBureaus(),
      this.getAllFormats(),
      this.getAllTitles(),
    ]);
    this.type_send = this.direct ? 4 : 1;
    await this.removePreloader();
  },
  methods: {
    async getBureaus() {
      try {
        let result = await LettersService.getAllBureaus();
        this.bureaus = result;
      } catch (error) {
        console.error("Error trying to get all bureaus", error);
      }
    },
    async getAllFormats() {
      try {
        let result = await LettersService.getAllFormats();
        this.formats = result;
      } catch (error) {
        console.error("Error trying to get all formats", error);
      }
    },
    async getAllTitles() {
      try {
        let result = await LettersService.getAllTitles();
        this.titles = result;
        if (this.direct) {
          this.title = this.titles[0].id;
        }
      } catch (error) {
        console.error("Error trying to get all titles", error);
      }
    },
    async searchUser() {
      try {
        this.isLoading = true;
        let response = await ClientesOptions.searchLetterUser({
          id: this.account,
        });
        if (response.status == 200) {
          this.nameClient = response.data[0].names;
          this.idaccount = response.data[0].idaccount;
          this.ss.ssn = response.data[0].ssn;
          this.ss.itin = response.data[0].itin;
          this.ss.other = response.data[0].other;
          this.firstsave = true;
          if (this.ss.ssn != null) {
            this.social = this.ss.ssn;
            this.type_social = 1;
          } else if (this.ss.itin != null) {
            this.social = this.ss.itin;
            this.type_social = 2;
          } else if (this.ss.other != null) {
            this.social = this.ss.other;
            this.type_social = 3;
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error("Error trying to get User", error);
      }
    },
    changeSocialType(type) {
      this.type_social = type;
    },
    async saveRoundLetter() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
          return;
        } else {
          const result = await this.showConfirmSwal();
          if (!result.isConfirmed) return;
          this.addPreloader();
          try {
            const response = await ClientesOptions.saveNewLetterAccount({
              id: this.idaccount,
              title: this.title,
              format: this.format,
              obs: this.obs,
              user: this.currentUser.user_id,
              social: this.social,
              type_social: this.type_social,
              type_send: this.type_send,
              bureau_id: this.bureau_id,
            });
            if (response.status === 200) {
              if (this.type_send == 4) {
                if (this.$route.matched[0].meta.module == 25) {
                  this.UPDATE_SIDEBAR_DIRECT_COUNTER({
                    routeName: "specialistdigital-directs-round",
                    tag:
                      this.pendingDirectRounds > 99
                        ? "+99"
                        : this.pendingDirectRounds + 1,
                  });
                  this.UPDATE_TAB_DIRECT_COUNTER({
                    pending: this.pendingDirectRounds + 1,
                  });
                } else if (this.$route.matched[0].meta.module == 11) {
                  specialistsCounter(); //update global counter specialist
                  analystDepartmentCounter();
                }
              }
              this.removePreloader();
              this.showSuccessSwal();
              this.$emit("updateTable");
              this.closeModal();
            }
            this.removePreloader();
          } catch (error) {
            this.removePreloader();
            console.error("Error trying to edit a round letter", error);
          }
        }
      });
    },
    closeModal() {
      this.modalOpened = false;
      this.$emit("hideModal");
    },
    close() {
      this.modalOpened = false;
      this.$emit("hideModal");
    },
    ...mapMutations({
      UPDATE_SIDEBAR_DIRECT_COUNTER:
        "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    ...mapActions({
      UPDATE_TAB_DIRECT_COUNTER:
        "SpecialistsDigitalDirectRoundsStore/A_SET_COUNTER_PENDING_DIRECT_ROUNDS",
    }),
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      pendingDirectRounds:
        "SpecialistsDigitalDirectRoundsStore/G_COUNTER_PENDING_DIRECT_ROUNDS",
    }),
  },
};
</script>
